import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Menu } from '../icons';
import { Flex } from '../layouts/Flex';
import { Link } from '../links/Link';
import { Text } from '../text/index';
import { colors } from '../theme';
import { MainWrapper } from '../wrappers/MainWrapper';
import { NavBarMenu } from './NavBarMenu';

const OuterNavBar = styled.nav`
    box-sizing: border-box;
    padding: 1rem 0;
    background: ${colors.black};
`;

const MenuIcon = styled(Menu)`
    cursor: pointer;
`;

export const NavBar: FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <OuterNavBar>
            <MainWrapper>
                <Flex justifyContent="space-between" alignItems="center" spacing={menuOpen ? 2 : 0}>
                    <Link to="/">
                        <Text fontSize={3} fontColor={colors.white}>
                            Shark Tank Graveyard
                        </Text>
                    </Link>
                    <MenuIcon
                        onClick={() => setMenuOpen((prev) => !prev)}
                        width={40}
                        height={40}
                        stroke={colors.white}
                    />
                </Flex>
                {menuOpen && <NavBarMenu />}
            </MainWrapper>
        </OuterNavBar>
    );
};

import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { MainWrapper } from './wrappers/MainWrapper';
import { NavBar } from './navigation/NavBar';

const LayoutQuery = graphql`
    query Layout {
        site {
            siteMetadata {
                title
            }
        }
    }
`;

export const Layout: FC = ({ children }) => {
    const data = useStaticQuery(LayoutQuery);
    console.log(data);

    return (
        <div>
            <Helmet></Helmet>
            <NavBar />
            <MainWrapper>{children}</MainWrapper>
        </div>
    );
};

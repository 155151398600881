interface Color {
    black: string;
    white: string;
}

export const colors: Color = {
    black: '#333333',
    white: '#FFFFFF',
};

export const fontSizes = {
    0: '0.75rem',
    1: '1rem',
    2: '1.25rem',
    3: '1.5rem',
    4: '2rem',
    5: '3rem',
};

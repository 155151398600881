import React, { FC } from 'react';
import { Flex } from '../layouts/Flex';
import { Link } from '../links/Link';
import { Label } from '../text/index';

const NavBarMenuLabel: FC = ({ children }) => <Label pointer>{children}</Label>;

export const NavBarMenu: FC = () => (
    <Flex justifyContent="space-between">
        <NavBarMenuLabel>
            <Link to="/seasons">Seasons</Link>
        </NavBarMenuLabel>
        <NavBarMenuLabel>
            <Link to="/episodes">Episodes</Link>
        </NavBarMenuLabel>
        <NavBarMenuLabel>
            <Link to="/pitches">Pitches</Link>
        </NavBarMenuLabel>
        <NavBarMenuLabel>
            <Link to="/sharks">Sharks</Link>
        </NavBarMenuLabel>
    </Flex>
);

import styled from 'styled-components';

export interface BaseProps {
    spacing?: number;
    pointer?: boolean;
}

export const Base = styled.div<BaseProps>`
    margin-bottom: ${({ spacing = 0 }) => `${spacing}rem`};
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'unset')};
`;

import React, { FC, SVGProps } from 'react';

export const Menu: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
        className="w-6 h-6"
        fill="none"
        stroke="currentColor"
        {...props}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
    </svg>
);

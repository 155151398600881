import React, { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

const StyledGatsbyLink = styled(GatsbyLink)`
    text-decoration: none;
    color: unset;

    &:hover {
        text-decoration: underline;
    }
`;

const StyledATag = styled.a``;

interface LinkProps {
    to?: string;
    onClick?: () => void;
    href?: string;
}

export const Link: FC<LinkProps> = ({ to, onClick, children, href }) => {
    if (to) return <StyledGatsbyLink to={to}>{children}</StyledGatsbyLink>;

    return (
        <StyledATag onClick={onClick} href={href}>
            {children}
        </StyledATag>
    );
};

import styled from 'styled-components';
import { Base, BaseProps } from './Base';

type JustifyContent = 'space-between';
type AlignItems = 'center';

interface FlexProps extends BaseProps {
    justifyContent?: JustifyContent;
    alignItems?: AlignItems;
}

export const Flex = styled(Base)<FlexProps>`
    display: flex;
    justify-content: ${({ justifyContent = 'unset' }) => justifyContent};
    align-items: ${({ alignItems = 'unset' }) => alignItems};
`;

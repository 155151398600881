import styled from 'styled-components';
import { Base, BaseProps } from '../layouts/Base';
import { colors, fontSizes as fonts } from '../theme';

interface TextProps extends BaseProps {
    fontColor?: string;
    fontSize?: keyof typeof fonts;
    upper?: boolean;
    bold?: boolean;
}

export const Text = styled(Base)<TextProps>`
    font-family: newsreader;
    font-size: ${({ fontSize = 1 }) => fonts[fontSize]};
    color: ${({ fontColor }) => fontColor || colors.black};
    text-transform: ${({ upper }) => (upper ? 'uppercase' : 'unset')};
    font-weight: ${({ bold }) => (bold ? '800' : 'unset')};
`;
